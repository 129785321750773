import React, { useState } from "react";
import { InputGroup, FormControl, Button, Form } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import { addNewTodo } from "../actions/todoAction";
import { useDispatch } from "react-redux";

function InputTask({ totalTask }) {
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    const time = new Date();
    const month = time.getMonth() + 1;
    let payload = [
      {
        id: totalTask + 1,
        title: title,
        description: "",
        status: 0,
        createdAt:
          time.getFullYear() +
          "-" +
          month +
          "-" +
          time.getDate() +
          " " +
          time.getHours() +
          ":" +
          time.getMinutes(),
      },
    ];
    event.preventDefault();
    dispatch(addNewTodo(payload));
    setTitle("");
  };

  return (
    <>
      <Form onSubmit={(event) => handleSubmit(event)}>
        <InputGroup>
          <FormControl
            size="md"
            placeholder="Add your new todo"
            onChange={(event) => setTitle(event.target.value)}
            value={title}
          />
          <Button type="submit" variant="success" disabled={title === ""}>
            <GoPlus />
          </Button>
        </InputGroup>
      </Form>
    </>
  );
}

export default InputTask;
