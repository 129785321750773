import axios from "axios";
import { sortDateByAscending, sortDateByDescending } from "../utils/sorting";
import { BASE_URL } from "../utils/url";

export const GET_TODO_LIST = "GET_TODO_LIST";
export const ADD_NEW_TODO = "ADD_NEW_TODO";
export const DETAIL_TODO = "DETAIL_TODO";
export const UPDATE_TASK_TODO = "UPDATE_TASK_TODO";
export const UPDATE_TASK_DONE_TODO = "UPDATE_TASK_DONE_TODO";
export const DELETE_TODO = "DELETE_TODO";
export const MARK_DONE_TASK = "MARK_DONE_TASK";
export const MARK_UNDONE_TASK = "MARK_UNDONE_TASK";

export const getTodoList = () => {
  return (dispatch) => {
    dispatch({
      type: GET_TODO_LIST,
      payload: {
        loading: true,
        data: false,
        errorMessage: false,
      },
    });

    axios({
      method: "GET",
      url: BASE_URL,
    })
      .then((res) => {
        let taskListData = [];
        let taskListDoneData = [];

        res.data.forEach((item) => {
          if (item.status === 1) {
            taskListDoneData.push(item);
          } else {
            taskListData.push(item);
          }
        });
        taskListData.sort(sortDateByAscending);
        taskListDoneData.sort(sortDateByDescending);
        dispatch({
          type: GET_TODO_LIST,
          payload: {
            loading: false,
            taskListData: taskListData,
            taskListDoneData: taskListDoneData,
            errorMessage: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TODO_LIST,
          payload: {
            loading: false,
            data: false,
            errorMessage: error.message,
          },
        });
      });
  };
};

export const addNewTodo = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_NEW_TODO,
      payload: data,
    });
  };
};

export const detailTodo = (data) => {
  return async (dispatch) => {
    dispatch({
      type: DETAIL_TODO,
      payload: {
        data: data,
      },
    });
  };
};

export const deleteTodo = (idx) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_TODO,
      payload: idx,
    });
  };
};

export const updateTaskTodo = (data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_TASK_TODO,
      payload: data,
    });
  };
};

export const updateTaskDoneTodo = (data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_TASK_DONE_TODO,
      payload: data,
    });
  };
};

export const markDoneTask = (index, data) => {
  const newData = { ...data, status: 1 };
  return async (dispatch) => {
    dispatch({
      type: MARK_DONE_TASK,
      payload: {
        index,
        data: [newData],
      },
    });
  };
};

export const markUndoneTask = (index, data) => {
  const newData = { ...data, status: 0 };
  return async (dispatch) => {
    dispatch({
      type: MARK_UNDONE_TASK,
      payload: {
        index,
        data: [newData],
      },
    });
  };
};
