export const sortDateByAscending = (a, b) => {
  var dateA = new Date(a.created_at);
  var dateB = new Date(b.created_at);
  return dateB > dateA ? 1 : -1;
};

export const sortDateByDescending = (a, b) => {
  var dateA = new Date(a.created_at);
  var dateB = new Date(b.created_at);
  return dateA > dateB ? 1 : -1;
};
