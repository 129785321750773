import React from "react";
import { MdEventNote } from "react-icons/md";
import InputTask from "./InputTask";
import TaskItem from "./TaskItem";

function TaskList({ data, loading, error, totalTask }) {
  return (
    <>
      <h6 className="mb-3">
        Task <MdEventNote />
      </h6>
      <InputTask totalTask={totalTask} />
      <TaskItem data={data} loading={loading} error={error} />
      {data && (
        <span className="text-count">
          You have <b>{data?.length}</b> pending tasks
        </span>
      )}
    </>
  );
}

export default TaskList;
