import {
  GET_TODO_LIST,
  ADD_NEW_TODO,
  DETAIL_TODO,
  UPDATE_TASK_TODO,
  UPDATE_TASK_DONE_TODO,
  DELETE_TODO,
  MARK_DONE_TASK,
  MARK_UNDONE_TASK,
} from "../../actions/todoAction";

const initialState = {
  taskListData: [],
  taskListDoneData: [],
  loading: false,
  error: false,
  detailTodoData: false,
};

const todo = (state = initialState, action) => {
  switch (action.type) {
    case GET_TODO_LIST:
      return {
        ...state,
        taskListData: action.payload.taskListData,
        taskListDoneData: action.payload.taskListDoneData,
        loading: action.payload.loading,
        error: action.payload.errorMessage,
      };
    case ADD_NEW_TODO:
      return {
        ...state,
        taskListData: action.payload.concat(state.taskListData),
      };
    case DETAIL_TODO:
      return {
        ...state,
        detailTodoData: action.payload.data,
      };
    case UPDATE_TASK_TODO:
      return {
        ...state,
        taskListData: state.taskListData.map((todo) =>
          todo.id === action.payload.id ? action.payload : todo
        ),
      };
    case UPDATE_TASK_DONE_TODO:
      return {
        ...state,
        taskListDoneData: state.taskListDoneData.map((todo) =>
          todo.id === action.payload.id ? action.payload : todo
        ),
      };
    case DELETE_TODO:
      return {
        ...state,
        taskListData: state.taskListData.filter(
          (task) => task.id !== action.payload
        ),
      };
    case MARK_DONE_TASK:
      return {
        ...state,
        taskListData: [
          ...state.taskListData.slice(0, action.payload.index),
          ...state.taskListData.splice(action.payload.index + 1),
        ],
        taskListDoneData: action.payload.data.concat(state.taskListDoneData),
      };
    case MARK_UNDONE_TASK:
      return {
        ...state,
        taskListData: action.payload.data.concat(state.taskListData),
        taskListDoneData: [
          ...state.taskListDoneData.slice(0, action.payload.index),
          ...state.taskListDoneData.splice(action.payload.index + 1),
        ],
      };
    default:
      return state;
  }
};

export default todo;
