import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TaskList from "./TaskList";
import TaskListDone from "./TaskListDone";
import { getTodoList } from "../actions/todoAction";
import { Container, Row, Col } from "react-bootstrap";

function TodoList() {
  const { taskListData, taskListDoneData, loading, error } = useSelector(
    (state) => state.TodoReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTodoList());
  }, [dispatch]);

  return (
    <>
      <Container>
        <Row className="justify-content-md-center">
          <Col lg="3">
            <TaskList
              data={taskListData}
              loading={loading}
              error={error}
              totalTask={taskListData?.length + taskListDoneData?.length}
            />
          </Col>
          <Col lg="3">
            <TaskListDone
              data={taskListDoneData}
              loading={loading}
              error={error}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TodoList;
