import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import TaskItem from "./TaskItem";

function TaskListDone({ data, loading, error }) {
  return (
    <>
      <h6 className="mb-3">
        Task Done <BsFillCheckCircleFill />
      </h6>
      <TaskItem data={data} loading={loading} error={error} />
      {data && (
        <span className="text-count">
          You have <b>{data?.length}</b> done tasks
        </span>
      )}
    </>
  );
}

export default TaskListDone;
