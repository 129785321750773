import React, { useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { MdOutlineTaskAlt } from "react-icons/md";
import ModalDetail from "./ModalDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  detailTodo,
  markDoneTask,
  markUndoneTask,
} from "../actions/todoAction";
import { RiEdit2Fill } from "react-icons/ri";
import { IoReturnDownBack } from "react-icons/io5";
import "../Todo.css";

function TaskItem({ data, loading, error }) {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const { detailTodoData } = useSelector((state) => state.TodoReducer);

  return (
    <>
      {data ? (
        data.map((todo, index) => {
          return (
            <Card key={index} className="my-1">
              <Card.Header
                onClick={() => {
                  setModalShow(true);
                  dispatch(detailTodo(todo));
                }}
              >
                {todo?.title}
              </Card.Header>
              <Card.Body>
                <p>{todo?.description}</p>
                <div className="d-flex justify-content-end">
                  {!todo?.status && (
                    <MdOutlineTaskAlt
                      className="icon"
                      onClick={() => dispatch(markDoneTask(index, todo))}
                    />
                  )}
                  {todo?.status ? (
                    <IoReturnDownBack
                      className="icon"
                      onClick={() => dispatch(markUndoneTask(index, todo))}
                    />
                  ) : null}
                  <RiEdit2Fill
                    className="icon"
                    onClick={() => {
                      setModalShow(true);
                      dispatch(detailTodo(todo));
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          );
        })
      ) : loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <p>{error ? error : "Server Error"}</p>
      )}

      <ModalDetail
        modalShow={modalShow}
        setModalShow={setModalShow}
        data={detailTodoData}
      />
    </>
  );
}

export default TaskItem;
