import React from "react";
import { Navbar, Container } from "react-bootstrap";
import { RiStickyNoteFill } from "react-icons/ri";

function Header() {
  return (
    <>
      <Navbar bg="transparent">
        <Container className="justify-content-center">
          <Navbar.Brand>
            <h5>
              <b>
                todo App <RiStickyNoteFill />
              </b>
            </h5>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
