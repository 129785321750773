import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  updateTaskTodo,
  updateTaskDoneTodo,
  deleteTodo,
} from "../actions/todoAction";
import { useDispatch } from "react-redux";

function ModalDetail({ modalShow, setModalShow, data }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setDescription(data.description);
    }
  }, [data, dispatch]);

  const updateTask = () => {
    let payload = {
      id: data.id,
      title: title,
      description: description,
      status: data.status === 0 ? 0 : 1,
      createdAt: data.createdAt,
    };
    dispatch(
      data.status === 0 ? updateTaskTodo(payload) : updateTaskDoneTodo(payload)
    );
    setModalShow(false);
  };

  const deleteTask = () => {
    dispatch(deleteTodo(data.id));
    setModalShow(false);
  };

  return (
    <div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detail Task
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              onChange={(event) => setTitle(event.target.value)}
              value={title}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(event) => setDescription(event.target.value)}
              value={description}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {!data.status && (
            <Button onClick={() => deleteTask()} variant="danger">
              Hapus
            </Button>
          )}
          <Button onClick={() => updateTask()} variant="success">
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalDetail;
